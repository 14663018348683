import useScope from '~/hooks/useScope'
import useViewSize from '~/hooks/useViewSize'
import * as Styled from './style'
import type * as Types from "./types"

import { ImageLoadingEffects } from '@gotamedia/fluffy/components/Image/components/LazyImage/types'

import cardsConfig from '~/config/adcards.json'
import type { AdCard, AdCardItem } from "~/config/types"

const Hero: Types.HeroComponent = () => {
    const { isLarge } = useViewSize()
    
    const scope = useScope()

    const card: AdCardItem = cardsConfig[scope.id as keyof AdCard]

    return (
        <>
            <Styled.HeroWrapper>
                <Styled.HeroBoxText>
                    <Styled.HeroBoxTextContent>
                        <Styled.PreHeadlineContainer>
                            <Styled.PreHeadline brandColor={scope.brandColor}>Privatannonser</Styled.PreHeadline>
                        </Styled.PreHeadlineContainer>

                        <Styled.MainHeadline>Boka din annons hos oss</Styled.MainHeadline>
                        {!isLarge() && 
                            <Styled.HeroBoxImgWrapper>
                                <Styled.HeroBoxImg 
                                    useNativeLazyLoading 
                                    loadingEffect={ImageLoadingEffects.Blur}
                                    src={"/images/privatannonser_mobile.png"} 
                                    alt={"Kvinna läser privatannonser sittandes vid ett bord och håller i en kaffekopp, på bordet ligger en papperstidning."} />
                            </Styled.HeroBoxImgWrapper>  
                        }  
                        <Styled.HeroText>Här kan du som privatperson boka och skapa annons för t.ex. födelsedag, bröllop, dop, dödsannonser, bostad, köp och sälj. Många av annonserna kan du bygga själv i vår digitala annonsstudio, där du väljer vilken tidning du vill publicera den i. </Styled.HeroText>
                        <Styled.HeroLink
                            target={"_blank"}
                            href={card.celebrate.buttonLink}
                            brandColor={scope.brandColor}>
                            Boka annons
                        </Styled.HeroLink>
                    </Styled.HeroBoxTextContent>
                </Styled.HeroBoxText>
                {isLarge() &&  
                    <Styled.HeroBoxImgWrapper>
                        <Styled.HeroBoxImg useNativeLazyLoading
                            loadingEffect={ImageLoadingEffects.Blur}
                            src={'/images/privatannonser_desktop.png'}
                            alt={"Kvinna läser privatannonser sittandes vid ett bord och håller i en kaffekopp, på bordet ligger en papperstidning."} />
                    </Styled.HeroBoxImgWrapper>
                }
                
            </Styled.HeroWrapper>
        </>
    )
}

export default Hero

// height={603} width={1024}